
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { mapStateToProps } from 'redux/selector/selector'
import { Route, Switch, withRouter } from 'react-router-dom'
import core from 'webapp/util/core'

class MainRoute extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            viewType: {
                width: 0,
                height: 0,
                isMobile: false,
                isLandScape: false
            },
            isMenuOpen: true,
        };
        core.updateWindowDimensions = core.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        core.updateWindowDimensions(this);
    }
    
    componentWillUnmount() {
        this.setState = () => {
           return;
        };
    }

    render() {
        const { viewType, isMenuOpen } = this.state;

        // Default redirect to home page
        // if (pathname === '/') {
        //     this.props.history.push("/home");
        // }
        return (
            <Fragment>
                <div className={"root"}>
                    {core.getCorrectTagName('navbar', viewType.isMobile)}
                    <div className={"body-content" + (isMenuOpen ? "" : " navOff")}>
                        <Switch>
                            <Route exact key="home" path="/" component={core.getCorrectComponent('home', viewType.isMobile)}></Route>
                            <Route exact key="portfolio-landing" path="/portfolio" component={core.getCorrectComponent('portfolio-landing', viewType.isMobile)}></Route>
                            <Route exact key="portfolio" path="/portfolio/:title" component={core.getCorrectComponent('portfolio', viewType.isMobile)}></Route>
                            <Route exact key="contact" path="/contact-us" component={core.getCorrectComponent('contact', viewType.isMobile)}></Route>
                            {/* <Route exact key="about-us" path="/about-us" component={core.getCorrectComponent('about-us', viewType.isMobile)}></Route> */}
                        </Switch>
                    </div>
                    {core.getCorrectTagName('footer', viewType.isMobile)}
                </div>
            </Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(MainRoute));
