import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { FaFacebookSquare, FaInstagram, FaMapMarkedAlt, FaEnvelope, FaPhoneSquareAlt, FaChevronDown } from 'react-icons/fa';
import { AiOutlineMenu } from 'react-icons/ai';

import core from 'webapp/util/core';
import vm from '../navbar.controller';

class Navbar extends Component {

    hideNav = false;

    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.initSettings();
    }

    componentWillUnmount() {
        this.setState = () => {
           return;
        };
    }

    render() {
        window.addEventListener("scroll", vm.handleScrolling, false);

        const { navSettings, socialMediaList, hideNav, isOpenNav, contactList } = this.state;
        return (
            <Fragment>
                <div className={"navbar-wrapper" + (hideNav ? " hideNav" : "") + (isOpenNav ? " isNavOpen" : "")}>
                    <div className="logo-container">
                        <img 
                            onClick={() => this.props.history.push('/')}
                            src='/content/icon/vision-logo.png'
                            alt="vs-logo" 
                        />
                    </div>
                    <div className="navbar-container">
                        { (navSettings || []).map((navItem, index) => (
                            <div className="nav-item" key={index} onClick={() => this.props.history.push(navItem.path)}>
                                { navItem.name }
                            </div>
                        ))}
                    </div>
                    <div className="social-media-container">
                        { (socialMediaList || []).map((smItem, index) => (
                            <div className="sm-item" key={index} onClick={() => core.handleContactClick(smItem)}>
                                { smItem.type === 'facebook' ?
                                    <FaFacebookSquare />
                                : smItem.type === 'instagram' ?
                                    <FaInstagram />
                                :
                                    null
                                }
                            </div>
                        ))}
                    </div>

                    <div className="navbar-ham" onClick={() => vm.toggleDropDownNav()}>
                        <AiOutlineMenu />
                    </div>
                </div>
                {isOpenNav && <div className="dropdown-overlay" onClick={() => vm.toggleDropDownNav()}></div> }
                <div className={"nav-dropdown-container" + (hideNav ? " hideNav" : "") + (isOpenNav ? " isOpen" : "")}>
                    <div className="nav-container">
                        { (navSettings || []).map((navItem, nindex) => (
                            <div className="nav-item" key={nindex} onClick={() => vm.redirectPath(navItem)}>
                                { navItem.name }
                            </div>
                        ))}
                    </div>
                    <div className="sm-container">
                        <p className="sm-title"><FaChevronDown />Get Connected</p>
                        { (socialMediaList || []).map((smItem, sindex) => (
                            <div className="sm-item" key={sindex} onClick={() => core.handleContactClick(smItem)}>
                                { smItem.type === 'facebook' ?
                                    <FaFacebookSquare />
                                : smItem.type === 'instagram' ?
                                    <FaInstagram />
                                :
                                    null
                                }
                            </div>
                        ))}
                        { (contactList || []).map((contact, cIndex) => (
                            <div className="sm-item" key={cIndex} onClick={() => core.handleContactClick(contact)}>
                                { contact.type === 'phone' ?
                                    <FaPhoneSquareAlt />
                                : contact.type === 'email' ?
                                    <FaEnvelope />
                                : contact.type === 'address' ?
                                    <FaMapMarkedAlt />
                                :
                                    null
                                }
                            </div>
                        ))}
                    </div>
                    <div className="credit-container">
                        @ NEO VISION STUDIO. ALL RIGHT RESERVED { this.state.currentYear }.
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default connect(mapStateToProps)(withRouter(Navbar));