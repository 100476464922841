import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import rootReducer from '../reducer/reducer'

const loadState = () => {
    return {};
}

const store = createStore(rootReducer, loadState(), applyMiddleware(thunkMiddleware));

export default store;