import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { FaMapMarkedAlt, FaEnvelope, FaPhoneSquareAlt } from 'react-icons/fa';
import core from 'webapp/util/core';

import vm from '../footer.controller';

class Footer extends Component {

    hideNav = false;

    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.initSettings();
    }

    componentWillUnmount() {
        this.setState = () => {
           return;
        };
    }

    render() {
        const { contactList } = this.state;

        return (
            <div className={"footer-wrapper"}>
                { !this.state.isHideContactList &&
                    <div className="footer-contact-container">
                        { (contactList || []).map((contact, index) => (
                            <div className="contact-item" key={index} onClick={() => core.handleContactClick(contact)}>
                                { contact.type === 'phone' ?
                                    <FaPhoneSquareAlt />
                                : contact.type === 'email' ?
                                    <FaEnvelope />
                                : contact.type === 'address' ?
                                    <FaMapMarkedAlt />
                                :
                                    null
                                }
                                <p>{ contact.href }</p>
                            </div>
                        ))}
                    </div>
                }

                <div className="footer-credit">
                    @ NEO VISION STUDIO SDN BHD. ALL RIGHT RESERVED { this.state.currentYear }.
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(withRouter(Footer));