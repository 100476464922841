
import jsonConfig from 'webapp/util/jsonConfig'

const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        controller.vm.state = {
            homeJson: {},
            homeQuote: null,
            collabShowList: [],
            landingVideo: {},
            homePortList: [],
            clientList: [],
            slidesList: [],
            currentSlideIndex: 0,
            showSlider: false
        }
    },

    initSettings() {
        let landingVideo = controller.vm.state.landingVideo;
        let aboutContent = controller.vm.state.aboutContent;

        jsonConfig.getServerJson(function (homeJson) {
            if (homeJson) {
                if (homeJson.landingVideo) {
                    landingVideo = homeJson.landingVideo;
                }

                if (homeJson.about) {
                    aboutContent = homeJson.about;
                }

                controller.vm.setState({ homeJson, landingVideo, aboutContent });
            }
        }, 'homeJson', controller.vm);

        jsonConfig.getServerJson(function (portfolioJson) {
            if (portfolioJson && portfolioJson.portfolio) {
                let homePortList = [];
                let portList = portfolioJson.portfolio;

                if (portList && portList.length > 0) {
                    portList.sort(function (a,b) {
                        return b.id - a.id;
                    });
                }

                // filter out only 6 items
                for (let i = 0; i < 13; i++) {
                    if (portList[i]) {
                        homePortList.push(portList[i]);
                    }
                }

                controller.vm.setState({ homePortList }, () => {
                    controller.renderCarouselSlides();
                });
            }
        }, 'portfolioJson', controller.vm);

        jsonConfig.getServerJson(function (clientJson) {
            if (clientJson && clientJson.collab) {
                controller.vm.setState({ clientList: clientJson.collab });
            }
        }, 'clientJson', controller.vm);
    },

    toggle3Dslider(action) {
        controller.vm.setState({ showSlider: action });
    },

    toggleSlideSelect(index) {
        let slidesList = controller.vm.state.slidesList;

        if (index < 0) {
            index = (slidesList.length - 1);
        } else if (index >= slidesList.length) {
            index = 0;
        }

        if (slidesList[index] && slidesList[index].obj) {
            slidesList[index].obj.className = "slide-item-main";
        }

        let leftSlidesIndex = index;
        let rightSldesIndex = index;

        for (let x = 0; x < 6; x++) {
            leftSlidesIndex--;
            if (leftSlidesIndex < 0) {
                leftSlidesIndex = (slidesList.length - 1);
            }

            if (x >= 4) {
                slidesList[leftSlidesIndex].obj.className = "slide-item--left";
            } else {
                slidesList[leftSlidesIndex].obj.className = "slide-item--" + (x + 1);
            }
        }

        for (let y = 0; y < 6; y++) {
            rightSldesIndex++;
            if (rightSldesIndex >= slidesList.length) {
                rightSldesIndex = 0;
            }

            if (y >= 4) {
                slidesList[rightSldesIndex].obj.className = "slide-item-right";
            } else {
                slidesList[rightSldesIndex].obj.className = "slide-item-" + (y + 1);
            }
        }
        
        controller.vm.setState({ slidesList, currentSlideIndex: index });
    },

    renderCarouselSlides() {
        const { homePortList } = controller.vm.state;
        let res = []

        for (let i = 0; i < homePortList.length; i++) {
            let imgPath = homePortList[i].thumbnail;
            res.push({
                obj: homePortList[i],
                html: 
                "<div class='slide-image-container'><div class='slide-title'><p>" + 
                homePortList[i].title + 
                "</p><p class='learn-more'>learn more</p>" +
                "</div><div class='slide-image' style='background: url(" + imgPath + ")'></div>" +
                "<div class='shadow-container'><div class='slide-shadow' style='background: url(" + imgPath + ")'" +
                "></div></div><div class='shadow-cover'></div></div>"
            });
        }

        controller.vm.setState({ slidesList: res }, () => {
            controller.toggleSlideSelect(0);
        });
        // return res;
    }
}

export default controller;

