
import jsonConfig from "webapp/util/jsonConfig";

const controller = {
    vm: null,
    
    init(vm) {
        controller.vm = vm;
        controller.vm.state = {
            contactList: [],
            currentYear: new Date().getFullYear(),
            isHideContactList: false
        }
    },

    initSettings() {
        if (window.location.pathname === '/contact-us') {
            controller.vm.setState({ isHideContactList: true });
        }

        jsonConfig.getServerJson(function (contactJson) {
            if (contactJson && contactJson.contact) {
                controller.vm.setState({ contactList: contactJson.contact });
            }
        }, 'contactJson', controller.vm);
    },
}

export default controller;