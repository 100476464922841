import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from 'react-icons/ai';

import vm from '../gallery-slider-3d/gallery-slider-3d.controller';
import commonService from 'webapp/util/common-service';

class GallerySlider3D extends Component {

    // Params:
    //  slidesList: [ARRAY] for all the slides with html
    //  totalSlidesShown: [INTERGER] the pages shown
    //  

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        vm.initSettings();
    }

    componentWillUnmount() {
        this.setState = () => {
           return;
        };
    }



    render() {
        const { slidesList } = this.state;

        return (
            <div className="gallery-slider-container" id="homeGallery">
                <div className="left-btn" onClick={() => this.props.toggleSlideSelect((this.props.currentSlideIndex + 1))}><AiOutlineDoubleLeft /></div>
                { (slidesList || []).map((slide, index) => (
                    <Fragment>
                        <div className={slide.obj && slide.obj.className ? slide.obj.className : "slide-item-0"}
                            onClick={() => {
                                (slide.obj && slide.obj.className === 'slide-item-main' ?
                                    commonService.handleRedirectPortfolio(slide.obj, this)
                                :
                                    this.props.toggleSlideSelect(index)
                                )
                            }} 
                            key={index} 
                            dangerouslySetInnerHTML={{ __html: (slide.html ? slide.html : "") }}></div>
                    </Fragment>
                ))}
                <div className="right-btn" onClick={() => this.props.toggleSlideSelect((this.props.currentSlideIndex - 1))}><AiOutlineDoubleRight /></div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(withRouter(GallerySlider3D));