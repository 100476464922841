
import core from 'webapp/util/core';
import jsonConfig from 'webapp/util/jsonConfig'

const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        controller.vm.state = {
            portObj: {},
            prevPortObj: {},
            nextPortObj: {},
            mainPortObj: {},
            btsPortObj: {},
            shareLink: window && window.location ? window.location.href : "https://visionstudio.my/",
            currentPFTitle: "",
            isVideoLoading: true,
            pathname: "",
            isGallery: false,
            redirectBackPath: null,
            isGalleryLoaded: false,
            galleryArr: [],
            isBottom: false
        }
    },

    updateViewType() {
        core.updateWindowDimensions(controller.vm);
    },

    initSettings() {
        const { currentPFTitle } = controller.vm.state;

        controller.vm.setState({ isPortLoading: true}, () => {
            jsonConfig.getServerJson(function (portfolioJson) {
                if (portfolioJson && portfolioJson.portfolio) {
                    let portList = portfolioJson.portfolio;
                    let isVideoLoading = controller.vm.state.isVideoLoading;
                    let isGallery = false;

                    for (let i = 0; i < portList.length; i++) {
                        if (portList[i].shortTitle === currentPFTitle) {

                            let prevIndex = i - 1;
                            let nextIndex = i + 1;

                            if (prevIndex < 0) {
                                prevIndex = portList.length - 1;
                            }

                            if (nextIndex > (portList.length - 1)) {
                                nextIndex = 0;
                            }

                            if (portList[i].gallery && portList[i].gallery.length > 0) {
                                isVideoLoading = false;
                                isGallery = true;
                            }

                            let mainPortObj = {};
                            if (portList[i].mainPortId) {
                                for (let j = 0; j < portList.length; j++) {
                                    if (portList[i].mainPortId === portList[j].id) {
                                        mainPortObj = portList[j];
                                    }
                                }
                            }

                            let btsPortObj = {};
                            if (portList[i].btsPortId) {
                                for (let x = 0; x < portList.length; x++) {
                                    if (portList[i].btsPortId === portList[x].id) {
                                        btsPortObj = portList[x];
                                    }
                                }
                            }

                            controller.vm.setState({ 
                                portObj: portList[i],
                                prevPortObj: portList[prevIndex],
                                nextPortObj: portList[nextIndex],
                                mainPortObj,
                                btsPortObj,
                                isVideoLoading,
                                isGallery,
                                shareLink: (window && window.location ? window.location.href : "") 
                            });
                            return;
                        }
                    }
                    controller.vm.props.history.push('/portfolio');
                }
            }, 'portfolioJson', controller.vm);
        });
    },

    getPortParams() {
        let pathname = window.location.pathname;
        let redirectBackPath = null;

        core.getURLParameters('redirectBack', function(data) {
            if (data) {
                if (data === 'home') {
                    redirectBackPath = '/';
                } else {
                    redirectBackPath = '/' + data;
                }
            }
        });

        let currentPFTitle = pathname.replace('/portfolio/', '');
        controller.vm.setState({ pathname, currentPFTitle, redirectBackPath }, () => {
            controller.initSettings();
        });
    },

    redirectByCategory(type) {
        controller.vm.props.history.push('/portfolio?category=' + type);
    },

    handleVideoLoaded() {
        controller.vm.setState({ isVideoLoading: false });
    },

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    },

    handleScrolling() {
        let isBottom = controller.vm.state.isBottom;
        const wrappedElement = document.getElementById('header');
        if (controller.isBottom(wrappedElement)) {
            controller.vm.setState({ isBottom: true });
            document.removeEventListener('scroll', this.trackScrolling);
        } else {
            if (isBottom) {
                controller.vm.setState({ isBottom: false });
            }
        }
    }
}

export default controller;