
const commonService = {
    handleRedirectPortfolio(port, vm) {
        let redirectPath = null;

        if (window.location && window.location.pathname === '/') {
            redirectPath = 'home';
        } else if (window.location && window.location.pathname === '/contact-us') {
            redirectPath = 'contact-us'
        }

        if (port && port.shortTitle) {
            vm.props.history.push('/portfolio/' + port.shortTitle + (redirectPath ? '?redirectBack=' + redirectPath : ''));
        }
    }
}

export default commonService;