import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { FaFacebookSquare, FaInstagram, FaMapMarkedAlt, FaEnvelope, FaPhoneSquareAlt } from 'react-icons/fa';
import ReCAPTCHA from 'react-google-recaptcha';

import { AiFillCloseCircle, AiFillCheckCircle } from 'react-icons/ai';

import vm from '../contact.controller';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import core from 'webapp/util/core';

let recaptchaRef = React.createRef();
class Contact extends Component {

    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.initSettings();
    }

    componentWillUnmount() {
        this.setState = () => {
           return;
        };
    }
    
    render() {
        const { errors, fields } = this.state;
        return (
            <div className="contact-container">
                <LazyLoadImage 
                    className="contact-bg"
                    src="/content/home/contact-bg.jpg"
                    alt="contact-bg"
                />

                <div className={"notification-modal" + (this.state.showNotice ? '' : ' isClose' ) + (this.state.noticeType === 'success' ? ' success-box' : ' error-box')}
                    onClick={() => vm.toggleNotification()}
                >
                    { this.state.noticeType === 'success' ? 
                        <AiFillCheckCircle className="success-icon" />
                    :
                        <AiFillCloseCircle className="error-icon" />
                    }
                    <div className="notice-content">
                        { this.state.noticeContent } 
                    </div>
                </div>

                <h1 className="contact-title">CONTACT US</h1>
                <div className="contact-content">
                    <div className="sm-container">
                        { (this.state.contactList || []).map((contact, index) => (
                            <div className="contact-item" key={index} onClick={() => core.handleContactClick(contact)}>
                                { contact.type === 'phone' ?
                                    <FaPhoneSquareAlt />
                                : contact.type === 'email' ?
                                    <FaEnvelope />
                                : contact.type === 'address' ?
                                    <FaMapMarkedAlt />
                                :
                                    null
                                }
                                { contact.href }
                            </div>
                        ))}
                        <div className="sm-item-container">
                            { (this.state.socialMediaList || []).map((smItem, sIndex) => (
                                <div className="sm-item" key={sIndex} onClick={() => core.handleContactClick(smItem)}>
                                    { smItem.type === 'facebook' ?
                                        <FaFacebookSquare />
                                    : smItem.type === 'instagram' ?
                                        <FaInstagram />
                                    :
                                        null
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="contact-form">
                        <h1>KEEP IN TOUCH</h1>
                        <p className="sub-header">Don’t be a stranger. We would like to hear from you as much as possible.</p>

                        <div className="form-field-container">
                            <div className="form-field">
                                <label>Name</label>
                                <input 
                                    type="text"
                                    name='from_name'
                                    value={fields.from_name}
                                    onChange={(e) => vm.handleChange(e)} 
                                />
                                <div className={"error-container" + ((!(errors['from_name']) || errors['from_name'] === '') ? " isHide" : "")}>
                                    { errors['from_name'] }
                                </div>
                            </div>

                            <div className="form-field">
                                <label>Email</label>
                                <input 
                                    type="email"
                                    name='user_email'
                                    value={fields.user_email}
                                    onChange={(e) => vm.handleChange(e)} 
                                />
                                <div className={"error-container" + ((!(errors['user_email']) || errors['user_email'] === '') ? " isHide" : "")}>{ errors['user_email'] }</div>
                            </div>

                            <div className="form-field">
                                <label>Subject </label>
                                <input 
                                    type="text"
                                    name='mail_subject'
                                    value={fields.mail_subject}
                                    onChange={(e) => vm.handleChange(e)}
                                />
                                <div className={"error-container" + ((!(errors['mail_subject']) || errors['mail_subject'] === '') ? " isHide" : "")}>{ errors['mail_subject'] }</div>
                            </div>

                            <div className="form-field">
                                <label>Phone Number </label>
                                <input 
                                    type="phone"
                                    name='user_phone'
                                    value={fields.user_phone}
                                    onChange={(e) => vm.handleChange(e)}
                                />
                                <div className={"error-container" + ((!(errors['user_phone']) || errors['user_phone'] === '') ? " isHide" : "")}>{ errors['user_phone'] }</div>
                            </div>

                        </div>

                        <div className="form-field msg-container">
                            <label>Message</label>
                            <textarea
                                name='message'
                                value={fields.message}
                                onChange={(e) => vm.handleChange(e)}
                            >
                            </textarea>
                            <div className={"error-container" + ((!(errors['message']) || errors['message'] === '') ? " isHide" : "")}>{ errors['message'] }</div>
                        </div>

                        <ReCAPTCHA
                            ref={ recaptchaRef }
                            className="recaptcha-container"
                            size="invisible"
                            sitekey={this.state.reCaptchaKey}
                            onChange={(e) => vm.onReCaptchaChange(e)}
                        />
                        <button className="submit-btn" onClick={() => recaptchaRef.current.execute()}>
                            <p>SUBMIT</p> <FaEnvelope />
                        </button>

                    </div>
                </div>
                
            </div>
        );
    }
}

export default connect(mapStateToProps)(withRouter(Contact));