
import Navbar from 'webapp/layout/navbar/component/navbar';
import Footer from 'webapp/layout/footer/component/footer';

import HOME from 'webapp/portal/home/component/home';
import PORTFOLIOLANDING from 'webapp/portal/portfolio/component/portfolio-landing';
import PORTFOLIO from 'webapp/portal/portfolio/component/portfolio';
import CONTACT from 'webapp/portal/contact/component/contact'; 

const core = {
    getCorrectTagName(type, isMobile, props = {}) {
        let tagName = null;
        switch (type) {
            case 'navbar': isMobile ? tagName = Navbar : tagName = Navbar ; break;
            case 'footer': isMobile ? tagName = Footer : tagName = Footer ; break;
            default: break;
        }
        const TagName = tagName;
        return <TagName {...props} />
    },

    getCorrectComponent(type, isMobile) {
        let component = null;
        switch (type) {
            case 'home':        isMobile ? component = HOME : component = HOME ; break;
            case 'portfolio-landing':   isMobile ? component = PORTFOLIOLANDING : component = PORTFOLIOLANDING; break;
            case 'portfolio':   isMobile ? component = PORTFOLIO : component = PORTFOLIO; break;
            case 'contact':     isMobile ? component = CONTACT : component = CONTACT; break;
            // case 'about-us':    isMobile ? component = MABOUTUS : component = ABOUTUS; break;
            default: break;
        }
        return component;
    },

    updateWindowDimensions(state) {
        const { innerWidth, innerHeight } = window;
        let viewType = {}
        viewType.width = innerWidth;
        viewType.height = innerHeight;
        viewType.isMobile = false;
        viewType.isLandScape = false;

        if (innerWidth <= 768 && (innerWidth < innerHeight)) {
            viewType.isMobile = true;
        }
        state.setState({ viewType });
    },

    handleContactClick(contact) {
        if (contact) {
            if (contact.href) {
                let loc = core.getContactUrl(contact);
                if (loc) {
                    window.open(loc);
                }
            }
        }
    },

    getURLParameters(params, callback) {
        let queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        callback(urlParams.get(params));
    },

    replaceAll(str, search, replacement) {
        return str.replace(new RegExp(search, 'g'), replacement);
    },

    getContactUrl(contact) {
        let value = contact.href.replace(/ /g, '');
        switch (contact.type) {
            case 'whatsapp':
                value = value.replace('+', '');
                return 'https://api.whatsapp.com/send?phone=' + value;
            case 'wechat':
                return 'weixin://dl/chat?' + value;
            case 'phone':
                return 'tel:' + value;
            case 'line':
                return 'http://line.me/ti/p/~' + value;
            case 'telegram':
                return 'https://t.me/' + value;
            case 'email':
            case 'yahoo':
                return 'mailto:' + value;
            case 'skype':
                return 'skype:' + value + '?chat';
            case 'zalo':
                return 'https://zalo.me/' + value;
            case 'facebook':
                return 'https://www.facebook.com/' + value;
            case 'instagram':
                return 'http://instagram.com/_u/' + value;
            case 'twitter':
                return 'https://twitter.com/' + value + '?s=09';
            case 'livechat':
                window.open(value, '', 'width=500,height=700,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=no');
                return '';

            case 'address':
                return 'https://www.google.com/maps/place/Ketumbar+Hill+Condo/@3.1034964,101.7475942,19z/data=!3m1!4b1!4m5!3m4!1s0x31cc35d24e998659:0xc16163a919d5eaee!8m2!3d3.1034951!4d101.7481427';
            default:
                return '';
        }
    }
}

export default core;