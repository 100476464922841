
import core from 'webapp/util/core';
import jsonConfig from 'webapp/util/jsonConfig'

const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        controller.vm.state = {
            portfolioList: [],
            displayList: [],
            pfCategories: [],
            categorySelected: {},
            isNoPort: false,
            isLoadedLanding: false,
            isPortLoading: false,
            showPopUpCategory: false,
            totalPages: [],
            numOfPage: 1
        }
    },

    initSettings() {
        controller.vm.setState({ isPortLoading: true}, () => {
            jsonConfig.getServerJson(function (portfolioJson) {
                if (portfolioJson && portfolioJson.portfolio) {
                    controller.vm.setState({ 
                        portfolioList: portfolioJson.portfolio, 
                        displayList: portfolioJson.portfolio,
                        pfCategories: portfolioJson.categories ? portfolioJson.categories : []
                    }, () => {
                        const { pfCategories } = controller.vm.state;
                        for (let i = 0; i < pfCategories.length; i++) {
                            controller.toggleCategory(pfCategories[i], (i === (pfCategories.length - 1)));
                        }
                    });
                }
            }, 'portfolioJson', controller.vm);
        });
    },

    getCategoryParam() {
        let categorySelected = controller.vm.state.categorySelected;

        core.getURLParameters('category', function(res) {
            if (res) {
                let resArray = res.split(',');

                // set show to false
                for (let key in categorySelected) {
                    if (resArray.includes(key)) {
                        categorySelected[key] = true;
                    } else {
                        categorySelected[key] = false;
                    }
                }

                controller.vm.setState({ categorySelected }, () => {
                    controller.filterPortByType();
                });
            }
        });
    },

    filterPortByType() {
        const { categorySelected } = controller.vm.state;
        let isNoPort = false;
        let portfolioList = controller.vm.state.portfolioList;
        let numOfPage = controller.vm.state.numOfPage;
        let totalPages = [];
        let displayList = [];

        controller.vm.setState({ isPortLoading: true}, () => {
            for (let i = 0; i < portfolioList.length; i++) {
                if (portfolioList[i]) {
                    portfolioList[i].isSelected = false;
                }
            }

            for (let key in categorySelected) {
                if (categorySelected[key]) {

                    for (let i = 0; i < portfolioList.length; i++) {
                        if (portfolioList[i] && portfolioList[i].category && portfolioList[i].category.includes(key) && !portfolioList[i].isSelected) {
                            displayList.push(portfolioList[i]);
                            portfolioList[i].isSelected = true;
                        }
                    }
                }
            }

            let rawTotal = displayList.length / 12;
            let rawTotalPages = Math.ceil(rawTotal);

            for (let j = 0; j < rawTotalPages; j++) {
                totalPages.push(j + 1);
            }

            displayList = displayList.slice((12 * (numOfPage - 1)), (12 * numOfPage));

            if (displayList.length <= 0) {
               isNoPort = true;
            } else {
                displayList.sort(function (a,b) {
                    return b.id - a.id;
                });
            }

            controller.vm.setState({ displayList, isNoPort, totalPages });
            setTimeout(() => {
                controller.vm.setState({ isPortLoading: false });
            }, 300);
        });

    },

    toggleCategory(type, lastIndex, isClearAll) {
        let categorySelected = controller.vm.state.categorySelected;

        if (isClearAll) {
            for (let key in categorySelected) {
                categorySelected[key] = false;
            }
        } else {
            if (type) {
                let currentType = controller.vm.state.categorySelected[type];
                categorySelected[type] = !currentType;
            }
        }
        

        controller.vm.setState({ numOfPage: 1, categorySelected }, () => {
            controller.filterPortByType();
            if (!controller.vm.state.isLoadedLanding && lastIndex) {
                controller.vm.setState({ isLoadedLanding: true }, () => {
                    controller.getCategoryParam();
                });
            }
        });
    },

    handlePageClick(numOfPage) {
        let totalPages = controller.vm.state.totalPages;

        if (numOfPage > 0 && numOfPage <= totalPages.length) {
            controller.vm.setState({ numOfPage }, () => {
                controller.filterPortByType();
            });
        } else {
            return;
        }
    },
    
    togglePopUpCategory() {
        controller.vm.setState({ showPopUpCategory: !controller.vm.state.showPopUpCategory });
    }
}

export default controller;