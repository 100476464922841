
const passKey = {
    mailKey: {
        serviceKey: "service_ts00rn6",
        templateKey: "template_qb2564q",
        userId: "user_7I2f91nhl5LxX744fPLqb",
        toName: "Neo Vision Studio"
    },
    googleReCaptcha: {
        siteKey: "6LfTjaYcAAAAACfsThQS9QHqCxl4EqGhuhD_JvFN"
    }
}

export default passKey;
