
import { filesAction } from 'redux/action'

const jsonConfig = {

    path: {
        homeJson: "/content/settings/home.json",
        contactJson: "/content/settings/contact.json",
        portfolioJson: "/content/settings/portfolio.json",
        clientJson: "/content/settings/client.json"
    },

    defaultPath: {
        homeJson: "/content/settings/home.json",
        contactJson: "/content/settings/contact.json",
        portfolioJson: "/content/settings/portfolio.json",
        clientJson: "/content/settings/client.json"
    },

    readTextFile(file, callback) {
        var rawFile = new XMLHttpRequest();
        rawFile.overrideMimeType("application/json");
        rawFile.open("GET", file, true);
        rawFile.onreadystatechange = function() {
            if (rawFile.readyState === 4 && rawFile.status === 200) {
                callback(rawFile.responseText);
            } else {
                callback(null);
            }
        }
        rawFile.send(null);
    },

    getJsonFile(type, callback) {
        jsonConfig.readTextFile(jsonConfig.path[type], function(text) {
            if (text) {
                var json = JSON.parse(text);
                if (json && json.data) {
                    callback(json.data);
                    return;
                } else {
                    jsonConfig.readTextFile(jsonConfig.defaultPath[type], function(dText) {
                        if (dText) {
                            var dJson = JSON.parse(dText);
                            if (dJson && dJson.data) {
                                callback(dJson.data);
                                return;
                            } else {
                                callback({});
                            }
                        }
                    });
                }
            }
        });
    },

    getServerJson(callback, type, vm) {
        let serverFiles = {};
        if (vm.props && vm.props.files && vm.props.files.serverFiles) {
            serverFiles = vm.props.files.serverFiles;
        }

        if (serverFiles && serverFiles[type]) {
            callback(serverFiles[type]);
        } else {
            jsonConfig.getJsonFile(type, function(res) {
                serverFiles[type] = res;
                if (vm && vm.props) {
                    vm.props.dispatch(filesAction.setJsonFiles(serverFiles));
                }
                callback(res);
            });
        }
    },
}

export default jsonConfig;