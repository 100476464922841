import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'redux/selector/selector';
import { withRouter } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Galleryslider3D from 'webapp/component/gallery-slider-3d/gallery-slider-3d';
import { FaVideo } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';

import vm from '../home.controller';
import commonService from 'webapp/util/common-service';

class Home extends Component {

    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.initSettings();
    }

    componentWillUnmount() {
        this.setState = () => {
           return;
        };
    }
    
    render() {
        const { landingVideo, aboutContent, homePortList, clientList } = this.state;

        let Galleryslider3DProps = {
            slidesList: this.state.slidesList,
            totalSlidesShown: 9,
            currentSlideIndex: this.state.currentSlideIndex,
            toggleSlideSelect: vm.toggleSlideSelect
        }
        
        return (
            <div className="home-container">
                <ReactTooltip place="bottom" />

                <div className="landing-wrapper">
                    <div className={"landing-container" + (this.state.showSlider ? ' isShowSlider' : '')}>
                        <ReactPlayer 
                            className={"landing-video"}
                            width="undefined" height="undefined"
                            muted={true}
                            controls={false}
                            playing={true}
                            playsinline={true}
                            loop={true}
                            url={landingVideo.src}
                        />
                        <div className="landing-cover" data-tip="Click to Learn More" onClick={() => vm.toggle3Dslider(true)}></div>
                    </div>

                    { this.state.showSlider &&
                        <div className={'slider-3d-container' + (this.state.showSlider ? ' isShowSlider' : '')}>
                            <Galleryslider3D {...Galleryslider3DProps} />
                        </div>
                    }
                    

                    <div className={"view-video-container"  + (this.state.showSlider ? ' isShowSlider' : '')} onClick={() => vm.toggle3Dslider(false)}>
                        <FaVideo />
                        <p>VIEW VIDEO</p>
                    </div>
                </div>

                { aboutContent && 
                    <div className="home-about-container">
                        <div className="head-container">
                            <div className="head-title">
                                ABOUT US
                            </div>
                            <div className="img-container" style={{ backgroundImage: `url(${'/content/home/about-bg.png'})` }}></div>
                        </div>
                        <div className="content-container">
                            <h1>{aboutContent.title}</h1>
                            { (aboutContent.desc || []).map((desc, index) => (
                                <div className="desc-container" key={index}>
                                    { desc }
                                </div>
                            ))}
                        </div>
                    </div>
                }

                { homePortList &&
                    <Fragment>
                        <div className="home-portfolio-title">
                            <p className="header">PORTFOLIO</p>
                            <p className="subHeader">RECENT PROJECTS</p>
                        </div>
                        <div className="home-portfolio-container">
                            { (homePortList.slice(0, 6) || []).map((pfItem, pfIndex) => (
                                <div className="pf-item">
                                    <div className="pf-inner-wrapper" key={pfIndex} onClick={() => commonService.handleRedirectPortfolio(pfItem, this)}>
                                        <LazyLoadImage 
                                            className="pf-image" 
                                            alt={pfItem.title} 
                                            src={pfItem.thumbnail}
                                        />
                                        <div className="pf-content">
                                            <div className="pf-inner">
                                                <p>{pfItem.title}</p>
                                                <hr />
                                                <p className="pf-learn">learn more</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pf-desc">{pfItem.title}</div>
                                </div>
                            ))}
                        </div>
                    </Fragment>
                }

                { clientList &&
                    <Fragment>
                        <div className="client-title">
                            <p className="header">SERVICES</p>
                            <p className="subHeader">OUR CLIENTS</p>
                        </div>
                        <div className="home-client-container">
                            
                            { (clientList || []).map((client, cindex) => (
                                <div className="client-item" key={cindex}>
                                    <LazyLoadImage 
                                        className="client-image" 
                                        style={{ width: client.width ? client.width : undefined, height: client.height ? client.height : undefined }}
                                        alt={client.name} 
                                        src={client.src}
                                    />
                                </div>
                            ))}
                        </div>
                    </Fragment>
                }
                
            </div>
        );
    }
}

export default connect(mapStateToProps)(withRouter(Home));