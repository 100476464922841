
import MainRoute from './webapp/portal/main-route';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'redux/store/store'; // redux store
import './webapp/scss/base.scss';

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <MainRoute></MainRoute>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
