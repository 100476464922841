import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import LoadingIcon from 'webapp/component/loading-icon';

import { FaFilter, FaArrowLeft } from 'react-icons/fa';
import { AiOutlineSearch, AiFillCloseCircle } from 'react-icons/ai';

import vm from '../portfolio-landing.controller';
import commonService from 'webapp/util/common-service';
import Pagination from 'webapp/component/pagination';

class PortfolioLanding extends Component {

    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.initSettings();
        vm.filterPortByType();
        vm.toggleCategory();
        vm.getCategoryParam();
    }

    componentWillUnmount() {
        this.setState = () => {
           return;
        };
    }
    
    render() {
        const { displayList, categorySelected, pfCategories, isPortLoading } = this.state;
        const { files } = this.props;

        let paginationProps = {
            totalPages: this.state.totalPages,
            numOfPage: this.state.numOfPage,
            handlePageClick: vm.handlePageClick
        }

        return (
            <div className={"portfolio-landing-wrapper" + (this.state.showPopUpCategory ? " filtersOpen" : "")}>
                <div className="category-container">
                    <div className="filter-title"><FaFilter />FILTER : </div>
                    {
                        pfCategories.map((type, tIndex) => (
                            <div 
                                key={tIndex} 
                                className={"category-item" + (categorySelected[type] ? " isSelected" : "")}
                                onClick={() => vm.toggleCategory(type)}
                            >
                                {type}
                            </div>
                        ))
                    }

                    { !this.state.isNoPort &&
                        <div className={"clear-btn" + (this.state.isNoPort ? "" : " isShow")} onClick={() => {vm.toggleCategory(null, null, true)}}>
                            <AiFillCloseCircle />clear all
                        </div>
                    }

                    <FaArrowLeft className={"hint-arrow" + (this.state.isNoPort ? " isShow" : "") } />
                </div>

                <div className={"category-popup-container" + ((files && files.hideNav) ? " hideNav" : "")}>
                    <div className="filter-btn" onClick={() => vm.togglePopUpCategory()}><FaFilter />Filters</div>
                </div>
                <div className={"filter-container" + (this.state.showPopUpCategory ? " isShow" : "") + ((files && files.hideNav) ? " hideNav" : "") }>
                    <div className="header-section">
                        <p>Filters : </p>
                        <p onClick={() => {vm.toggleCategory(null, null, true)}}><AiFillCloseCircle />Clear All</p>
                    </div>
                    <div className="category-section">
                        {
                            pfCategories.map((type, tIndex) => (
                                <div 
                                    key={tIndex} 
                                    className={"category-item" + (categorySelected[type] ? " isSelected" : "")}
                                    onClick={() => vm.toggleCategory(type)}
                                >
                                    {type}
                                </div>
                            ))
                        }
                    </div>
                </div>

                { isPortLoading ? 
                    <Fragment>
                        <LoadingIcon />
                    </Fragment>
                :
                    <Fragment>
                        <div className={"portfolio-list-container" + ((files && files.hideNav) ? " hideNav" : "")}>
                            { (displayList || []).map((port, pIndex) => (
                                <div className="pf-conatiner" key={pIndex}>
                                    <div className="pf-item" key={pIndex} onClick={() => commonService.handleRedirectPortfolio(port, this)}>
                                        <LazyLoadImage
                                            src={port.thumbnail}
                                            alt={port.title}
                                        />
                                        <div className="pf-cover">
                                            { (port.category || []).map((type, tIndex) => (
                                                <p className="category-word" key={tIndex}>
                                                    {type}
                                                </p>
                                            ))}
                                            <hr />
                                            <p>{port.title}</p>
                                        </div>
                                    </div>
                                    <div className="pf-content">
                                        <p>{port.title}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        { this.state.isNoPort &&
                            <div className="no-port-container">
                                <AiOutlineSearch />
                                <p>Please choose a category to explore more</p>
                            </div>
                        }
                    </Fragment>
                }
                
                { !this.state.isNoPort &&
                    <Pagination {...paginationProps} />
                }
                 
            </div>
        );
    }
}

export default connect(mapStateToProps)(withRouter(PortfolioLanding));