import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiFillCaretDown, AiOutlineTwitter } from 'react-icons/ai';
import { FaFacebookF, FaWhatsapp, FaTelegramPlane, FaPinterestP, FaPhotoVideo, FaPlayCircle, FaSearch, FaRegCaretSquareLeft } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';

import commonService from 'webapp/util/common-service';
import LoadingIcon from 'webapp/component/loading-icon';

import {
    PinterestShareButton,
    FacebookShareButton,
    WhatsappShareButton,
    TelegramShareButton,
    TwitterShareButton
} from "react-share";

import vm from '../portfolio.controller';
import ReactPlayer from 'react-player';
import { Carousel } from '3d-react-carousal';

class Portfolio extends Component {

    constructor() {
        super();
        vm.init(this);
        this.goBack = this.goBack.bind(this);
    }

    goBack(){
        this.props.history.goBack();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        vm.getPortParams();
        vm.updateViewType();
    }

    componentDidUpdate() {
        document.addEventListener('scroll', vm.handleScrolling);
        this.renderSlides(this.state.portObj.gallery);
        if (this.state.pathname !== window.location.pathname) {
            this.setState({ isVideoLoading: true, isGalleryLoaded: false }, () => {
                this.renderSlides(this.state.portObj.gallery);
            });
            window.scrollTo(0, 0);
            vm.getPortParams();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', vm.handleScrolling);
        this.setState = () => {
           return;
        };
    }

    renderSlides(gallery) {
        let resArr = [];
        
        if (gallery && gallery.length > 0 && !this.state.isGalleryLoaded) {
            this.setState({ isVideoLoading: true, isGalleryLoaded: true }, () => {
                for (let i = 0; i < gallery.length; i++) {
                    resArr.push(
                        <LazyLoadImage 
                            src={"/content/portfolio/photography/" + gallery[i].src} 
                            alt={gallery[i].src} 
                        />
                    )
                }
                this.setState({ galleryArr: resArr }, () => {
                    this.setState({ isVideoLoading: false });
                });
            });
        }
    }

    render() {
        const { portObj, isVideoLoading, prevPortObj, nextPortObj, isGallery, mainPortObj, btsPortObj, isBottom } = this.state;
        const { files } = this.props;

        return (
            <div className="portfolio-container" id="header">
                <ReactTooltip place="bottom" />
                { !isBottom &&
                    <div className="nav-pop">
                        <p>Scroll Down to Learn More</p>
                        <AiFillCaretDown />
                    </div>
                }
                
                <div className="back-btn" onClick={() => {this.props.history.push(this.state.redirectBackPath ? this.state.redirectBackPath : '/portfolio')}}>
                    <FaRegCaretSquareLeft />
                    <p>Go Back</p>
                </div>

                <div className="bg-cover-container">
                    <LazyLoadImage 
                        className="img-cover"
                        src={portObj.thumbnail}
                        alt={portObj.title}
                    />
                    <div className="cover-cover"></div>
                </div>

                <div className={"content-wrapper" + (isGallery ? " gallery-type" : "")}>
                    <div className={"loading-wrapper" + (isVideoLoading ? "" : " isLoaded")}>
                        <LoadingIcon />
                    </div>

                    { isGallery ?
                        <Fragment>
                            { isVideoLoading ?
                                <LoadingIcon />
                            :
                                <div className="gallery-container">
                                    <Carousel slides={this.state.galleryArr} />
                                </div>
                            }
                        </Fragment>
                    :
                        <ReactPlayer 
                            className={"port-video"}
                            width="undefined" height="undefined"
                            controls={true}
                            url={portObj.src}
                            onReady={() => vm.handleVideoLoaded()}
                        />
                    }
                    
                </div>

                <div className="port-content-wrapper">
                    <p className="port-title">{ portObj.title }</p>
                    <hr />
                    { isGallery && portObj.src && 
                        <div className="sub-video-container">
                            <p className="sub-video-title">Video : </p>
                            <ReactPlayer 
                                className={"sub-video"}
                                width="undefined" height="undefined"
                                controls={true}
                                url={portObj.src}
                                onReady={() => vm.handleVideoLoaded()}
                            />
                            <hr />
                        </div>
                    }

                    { btsPortObj && btsPortObj.shortTitle && 
                        <Fragment>
                            <div className="bts-container" onClick={() => commonService.handleRedirectPortfolio(btsPortObj, this)}>
                                <p className="bts-title">Behind the screen : </p>
                                <LazyLoadImage 
                                    className="bts-image"
                                    src={btsPortObj.thumbnail}
                                    alt={btsPortObj.title}
                                />
                                <div className="bts-image-cover"></div>
                                <FaPlayCircle />
                            </div>
                            <hr />
                        </Fragment>
                    }

                    { mainPortObj && mainPortObj.shortTitle &&
                        <Fragment>
                            <div className="bts-container" onClick={() => commonService.handleRedirectPortfolio(mainPortObj, this)}>
                                <p className="bts-title">
                                    { mainPortObj.category && mainPortObj.category.includes('video') ?
                                        <span>The Video : </span>
                                    : mainPortObj.category && mainPortObj.category.includes('photography') ?
                                        <span>The Photography : </span>
                                    :
                                        <span>The Main Resource : </span>
                                    }
                                    
                                </p>
                                <LazyLoadImage 
                                    className="bts-image"
                                    src={mainPortObj.thumbnail}
                                    alt={mainPortObj.title}
                                />
                                <div className="bts-image-cover"></div>
                                { mainPortObj.category && mainPortObj.category.includes('video') ?
                                    <FaPlayCircle />
                                :
                                    <FaSearch />
                                }
                            </div>
                            <hr />
                        </Fragment>
                    }

                    <div className="content-container">
                        <div className="port-desc-container">
                            <h3>Description : </h3>
                            <div className="port-desc" dangerouslySetInnerHTML={{ __html: portObj.desc }}></div>
                        </div>

                        <div className="port-share">
                            <div className="category-container">
                                <h3>Category: </h3>
                                <div className="category-item-container">
                                    { (portObj.category || []).map((type, cindex) => (
                                        <p className="category-item" key={cindex}>
                                            { cindex !== 0 &&
                                                <span> / </span>
                                            }
                                            <span key={cindex} onClick={() => vm.redirectByCategory(type)}>{ type }</span>
                                        </p>
                                    ))}
                                </div>
                                
                            </div>
                            <div className="share-container">
                                <h3>SHARE: </h3>
                                <div className="sm-container">
                                    <FacebookShareButton url={this.state.shareLink}>
                                        <FaFacebookF />
                                    </FacebookShareButton>

                                    <TwitterShareButton url={this.state.shareLink} title={this.state.shareLink}>
                                        <AiOutlineTwitter />
                                    </TwitterShareButton>

                                    <WhatsappShareButton url={this.state.shareLink}>
                                        <FaWhatsapp />
                                    </WhatsappShareButton>

                                    <TelegramShareButton url={this.state.shareLink}>
                                        <FaTelegramPlane />
                                    </TelegramShareButton>

                                    <PinterestShareButton url={this.state.shareLink} media={portObj.thumbnail}>
                                        <FaPinterestP />
                                    </PinterestShareButton>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div className={"next-prev-container" + ((files && files.hideNav) ? " hideNav" : "")}>
                    <div className="next-btn" onClick={() => { commonService.handleRedirectPortfolio(this.state.prevPortObj, this) }}> 
                        <AiOutlineDoubleLeft /> 
                        <p className="short-dir">Previous</p>
                        <p>{ prevPortObj.title }</p> 
                    </div>
                    <div className="catalog-btn" data-tip="Back to Portfolio" onClick={() => this.props.history.push('/portfolio')}>
                        <FaPhotoVideo /> 
                        <p className="short-dir">Menu</p>
                    </div>
                    <div className="prev-btn" onClick={() => { commonService.handleRedirectPortfolio(this.state.nextPortObj, this) }}>
                        <p>{ nextPortObj.title }</p> 
                        <p className="short-dir">Next</p>
                        <AiOutlineDoubleRight /> 
                    </div>
                </div>

                
            </div>
        );
    }
}

export default connect(mapStateToProps)(withRouter(Portfolio));