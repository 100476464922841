import React, { Component } from 'react';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from 'react-icons/ai';

class LoadingIcon extends Component {

    constructor(props) {
        super();
        this.props = props;
    }

    componentWillUnmount() {
        this.setState = () => {
           return;
        };
    }

    render() {
        const { totalPages, numOfPage } = this.props;
        return (
            <div className="pagination-container">
                <div className="prev-btn" onClick={() => this.props.handlePageClick(numOfPage - 1)}><AiOutlineDoubleLeft /></div>
                { (totalPages || []).map((page, index) => (
                    <div 
                        key={index} 
                        className={"page-item" + (page === numOfPage ? " isSelected" : "")} 
                        onClick={() => this.props.handlePageClick(page)}
                    >
                        {page}
                    </div>
                ))}
                <div className="next-btn" onClick={() => this.props.handlePageClick(numOfPage + 1)}><AiOutlineDoubleRight /></div>
            </div>
        );
    }
}

export default LoadingIcon;