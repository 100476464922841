export const filesAction = {
    setJsonFiles,
    hideNavbar
};

function setJsonFiles(json) {
    return dispatch => {
        dispatch({ 
            type: 'serverFiles',
            payload: {
                serverFiles: json
            }
        });
    }
}

function hideNavbar(action) {
    return dispatch => {
        dispatch({ 
            type: 'hideNav',
            payload: {
                hideNav: action
            }
        });
    }
}
