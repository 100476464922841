import React, { Component } from 'react';
import { AiOutlineLoading } from 'react-icons/ai'

class LoadingIcon extends Component {
    render() {
        return (
            <div className="loading-icon-container">
                <AiOutlineLoading />
            </div>
        );
    }
}

export default LoadingIcon;