const initialState = {
    serverFiles: {}
};

export function files(state = initialState, action) {
    switch (action.type) {
        case 'serverFiles':
            return {
                ...state,
                serverFiles: action.payload.serverFiles
            };
        case 'hideNav':
            return {
                ...state,
                hideNav: action.payload.hideNav
            }
        default:
            return state;
    }
}
