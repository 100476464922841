import jsonConfig from "webapp/util/jsonConfig";
import { filesAction } from "redux/action";

const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        controller.vm.state = {
            homeJson: {},
            navSettings: [
                {
                    name: "HOME",
                    path: "/",
                    isSelected: true
                },
                {
                    name: "PORTFOLIO",
                    path: "/portfolio",
                    isSelected: false
                },
                {
                    name: "CONTACT US",
                    path: "/contact-us",
                    isSelected: false
                }
            ],
            socialMediaList: [],
            contactList: [],
            hideNav: false,
            lastScrollTop: 0,
            currentYear: new Date().getFullYear(),
            isOpenNav: false
        };
    },

    initSettings() {
        jsonConfig.getServerJson(function (homeJson) {
            if (homeJson) {
                controller.vm.setState({ homeJson });
            }
        }, 'homeJson', controller.vm);

        jsonConfig.getServerJson(function (contactJson) {
            if (contactJson) {
                if (contactJson.contact) {
                    controller.vm.setState({ contactList: contactJson.contact });
                }

                if (contactJson.socialMedia) {
                    controller.vm.setState({ socialMediaList: contactJson.socialMedia });
                }
            }
        }, 'contactJson', controller.vm);
    },

    handleScrolling() { 
        let lastScrollTop = controller.vm.state.lastScrollTop;
        let hideNav = controller.vm.state.hideNav;
        
        var st = window.pageYOffset || document.documentElement.scrollTop; 
        if (st > lastScrollTop){
            hideNav = true;
        } else {
            hideNav = false;
        }
        lastScrollTop = st <= 0 ? 0 : st;

        controller.vm.setState({
            lastScrollTop,
            hideNav
        });

        controller.vm.props.dispatch(filesAction.hideNavbar(hideNav));
    },

    toggleDropDownNav() {
        controller.vm.setState({ isOpenNav: !controller.vm.state.isOpenNav });
    },

    redirectPath(navItem) {
        controller.vm.props.history.push(navItem.path);
        controller.vm.setState({ isOpenNav: false });
    }
}

export default controller;