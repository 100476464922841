
const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        controller.vm.state = {
            totalSlidesShown: vm.props.totalSlidesShown,
            slidesList: (vm.props.slidesList && vm.props.slidesList.length > 0) ? vm.props.slidesList : [],
            xDown: null,
            yDown: null,
            swipeDisabled: false
        }
    },

    initSettings() {
        let homeGallery = document.getElementById('homeGallery');

        homeGallery.addEventListener('touchstart', controller.handleTouchStart, false);
        homeGallery.addEventListener('touchmove', controller.handleTouchMove, false);
    },

    getTouches(evt) {
        return evt.touches || evt.originalEvent.touches;
    },

    handleTouchStart(evt) {
        const firstTouch = controller.getTouches(evt)[0];
        let xDown = controller.vm.state.xDown;
        let yDown = controller.vm.state.yDown;

        xDown = firstTouch.clientX;                                      
        yDown = firstTouch.clientY;    
        
        controller.vm.setState({ xDown, yDown });
    },
                                                                             
    handleTouchMove(evt) {
        let xDown = controller.vm.state.xDown;
        let yDown = controller.vm.state.yDown;

        if ( !xDown || !yDown ) {
            return;
        }
    
        var xUp = evt.touches[0].clientX;                                    
        var yUp = evt.touches[0].clientY;
    
        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;
                                                                             
        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
            if (xDiff > 0) {
                if (!controller.vm.state.swipeDisabled) {
                    controller.vm.setState({ swipeDisabled: true }, () => {
                        controller.vm.props.toggleSlideSelect((controller.vm.props.currentSlideIndex - 1));
                        setTimeout(() => {
                            controller.vm.setState({ swipeDisabled: false });
                        }, 300);
                    });
                }
            } else {
                if (!controller.vm.state.swipeDisabled) {
                    controller.vm.setState({ swipeDisabled: true }, () => {
                        controller.vm.props.toggleSlideSelect((controller.vm.props.currentSlideIndex + 1));
                        setTimeout(() => {
                            controller.vm.setState({ swipeDisabled: false });
                        }, 300);
                    });
                }
            }                       
        }
        /* reset values */
        xDown = null;
        yDown = null;                                             
    }

}

export default controller;